.frontcopyright_sel{
  position: absolute;
  top: 0%;
  left: 0%;
  min-width: 100%;
  min-height: 100%;
}


img.frontcopyright_img_sel{
  width: 74% !important;
  height: 60% !important;
  z-index: -9999;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 11%;
 
}


.photo_sel{
  border: 0px solid #fff;
  width: 100%;
  height: 387px !important;
  overflow: hidden;
}

.raw_img_sel {
  width: 304px !important;
  height: 335px;
  object-fit: contain;
  transform: rotate(-90deg) scale(1.4);
  margin-left: -7px;
}

.frontcopyright_sele2{
  position: absolute;
  top: 10%;
  left: 15%;
  width: 90%;
  max-height: 90%;
 
}
div.frontcopyright_sele2 img{
  width: 80% !important ;
  max-height: 80% !important;
 
}
.selected_container {
  width: 96%;
  margin: auto;
}
.selected_container .head {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.selected_container .head h5 {
  font-size: 36px;
  font-weight: bold;
  color: #7fdc7b;
  text-align: center;
  margin: 10px 0;
}
.selected_container .head span {
  font-size: 26px;
  font-weight: 600;
  color: #333;
  cursor: pointer;
}
.selected_container .head .info {
  position: absolute;
  width: 36px;
  height: 36px;
  background: #fff;
  box-sizing: border-box;
  border: 3px solid #7cbddd;
  border-radius: 50%;
  /* bottom: -20px; */
  right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}
.selected_container .head span i {
  font-size: 30px;
  color: #111;
}
.selected_container .body {
  padding: 10px 15px;
  width: 92%;
  margin: auto;
}
.selected_container .body .images_cont {
  display: flex;
  flex-wrap: wrap;
}

.selected_container .body .images_cont .image .photo {
  border: 2px solid #fff;
  width: 100%;
  height: 350px !important;
  overflow: hidden;
}

.selected_container .body .images_cont .image,
.dialog_conta .image {
  width: 25%;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
}
.dialog_conta .image {
  height: 550px !important;
}
.selected_container .body .images_cont .image,
.dialog_conta .image {
  padding: 10px 13px 10px 10px;
}
.selected_container .body .images_cont .image .photo,
.dialog_conta .image .photo {
  border: 2px solid #fff;
  width: 100%;
  height: 370px;
  max-height: 380px;
  overflow: hidden;
}
.dialog_conta .image .photo.full {
  /* height: calc(100% - 20px) !important; */
  height: 507px;
  overflow: hidden;
  max-height: calc(100% - 30px);
}
.dialog_conta .image .photo.full img {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
  transform: rotate(-90deg) scale(1.5);
}
.selected_container .body .images_cont .image .photo img,
.dialog_conta .image .photo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: rotate(-90deg) scale(1.5);
}
.dialog_conta .image img.photo {
  height: calc(100% - 20px);
  max-height: 550px;
}
.selected_container .body .images_cont .image p,
.dialog_conta .image p {
  padding: 6px 10px;
  margin: 0;
  text-align: center;
  background: #fff;
  margin-top: -5px;
  font-size: 13px;
  width: calc(100% - 16px);
}
.selected_container .body .images_cont .image .comp_port,
.dialog_conta .image .comp_port {
  width: 37px;
  height: 45px;
  background: rgb(255, 255, 255);
  border: 0px solid rgb(121, 121, 121);
  position: absolute;
  top: 10px;
  left: 13px;
  opacity: 0.5;
  cursor: pointer;
}
.selected_container .body .images_cont .image .comp_port.active,
.dialog_conta .image .comp_port.active {
  background: #fff;
  opacity: 1;
}
.selected_container .body .images_cont .image .comp_port.reverse {
  right: 13px;
  left: auto;
}
.selected_container .body .images_cont .image .comp_port.reverse.active {
  background: #fff;
}
.dialog_conta .image .comp_port.reverse {
  right: 11px;
  left: auto;
}
.selected_container .body .images_cont .image .comp_port.reverse span,
.dialog_cont .image .comp_port.reverse span {
  top: -2px;
  left: 7px;
}
.selected_container .body .images_cont .image .comp_port span,
.dialog_conta .image .comp_port span {
  font-size: 41px;
  font-weight: 700;
  color: #333;
  position: absolute;
  top: -2px;
  left: 5px;
}
.selected_container .body .images_cont .image .comp_port.active span,
.dialog_conta .image .comp_port.active span {
  color: #7fdc7b;
}
.selected_container .body .images_cont .image .comp_port.reverse.active span,
.dialog_conta .image .comp_port.reverse.active span {
  color: #7cbddd;
}
.selected_container .body .images_cont .image span,
.dialog_conta .image span {
  position: absolute;
  top: 18px;
  left: 18px;
}
.selected_container .body .images_cont .image span img,
.dialog_conta .image span img {
  border: none;
  width: 30px;
  height: 26px;
}
.selected_container .body .images_cont .image span.add,
.dialog_conta .image span.add {
  bottom: 45px;
  right: 15px;
  top: auto;
  left: auto;
  width: 23px;
  text-align: center;
  line-height: 23px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #333;
}
.dialog_conta .image span.add {
  bottom: 25px;
}
.dialog_conta .image span.add {
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selected_container .body .images_cont .image span.add i,
.dialog_conta .image span.add i {
  font-size: 14px;
}
.selected_container .body button {
  background: rgba(235, 123, 119, 1);
  width: 250px;
  display: block;
  border-radius: 5px;
  padding: 10px 0;
  color: #fff;
  font-weight: 400;
  font-size: 22px;
  border: none;
  outline: none;
}
.selected_container .body button:disabled {
  background: rgba(170, 170, 170);
  cursor: default;
}

strong {
  font-size: 23px;
}

.selected_container .cropping {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}
.selected_container .cropping .img {
  width: 125px;
  height: 125px;
  margin-right: 15px;
}
.selected_container .cropping .img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.selected_container .cropping .details h6 {
  font-size: 23px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
}
.selected_container .cropping .details p {
  font-size: 16px;
  font-weight: normal;
  color: #333;
  margin-bottom: 5px;
}
.selected_container .cropping .details a {
  text-decoration: none;
  color: rgba(235, 123, 119, 1);
  font-size: 16px;
  font-weight: 600;
}
.dialog_conta {
  width: 100%;
  height: 100%;
}
.dialog_conta span {
  position: absolute;
  display: flex;
  top: 0px;
  right: 0px;
  cursor: pointer;
  z-index: 9;
}
.dialog_conta span {
  font-size: 25px;
}
.dialog_cont {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: rgb(238, 238, 231);
  border: 4px solid #7cbddd;
  /* max-height: 400px; */
}
.dialog_cont h5 {
  font-size: 36px;
  font-weight: bold;
  color: #7fdc7b;
  text-align: center;
  margin: 10px 0;
}

.widthmax {
  max-height: 600px !important;
}

.dialog_cont p {
  font-size: 22px;
  color: #333;
  font-weight: normal;
  text-align: center;
}
.dialog_cont .com_por {
  display: flex;
  padding: 20px;
  margin-top: 15px;
  justify-content: space-around;
  align-items: center;
}
.dialog_cont :is(.com, .por) {
  width: 50%;
  text-align: center;
}
.dialog_cont :is(.com, .por) p.letter {
  font-size: 40px;
  font-weight: 700;
  color: #7cbddd;
  border: 2px solid #333;
  box-sizing: border-box;
  /* width: 46px; */
  width: 40px;
  height: 46px;
  line-height: 42px;
  margin: auto;
}

.boldfont {
  font-family: 'proxima_nova_rgbold' !important;
}

.dialog_cont .com p.letter {
  color: #7fdc7b;
}
.dialog_cont :is(.com, .por) h6 {
  font-size: 26px;
  font-weight: 600;
  color: #333;
  margin-top: 6px;
}
.dialog_cont :is(.com, .por) p.desc {
  font-size: 16px;
  font-weight: normal;
  color: #333;
  margin: 0;
}
.dialog_cont {
  position: relative;
}
.dialog_cont .close {
  width: 30px;
  height: 30px;
  border: 2px solid #333;
  position: absolute;
  top: 4px;
  right: 2px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}
@media (max-width: 992px) {
  .selected_container .body .images_cont .image {
    width: 33.33% !important;
  }
}
@media (max-width: 768px) {
  .selected_container .body .images_cont .image {
    width: 50% !important;
    height: 50% !important;
  }
  .selected_container .body .images_cont .image .photo,
.dialog_conta .image .photo {
  max-height: 226px;
  overflow: hidden;
}

  .selected_container .body .images_cont .image_one {
    width: 100% !important;
  }
  .selected_container .cropping .details p {
    width: 90%;
  }
}
@media (max-width: 576px) {

  .photo_sel {
    border: 0px solid #fff;
    width: 100%;
    height: 219px !important;
    overflow: hidden;
  }

 

.raw_img_sel {
  width: 104% !important;
  height: 97%;
  object-fit: contain;
  
  transform: rotate(-90deg) scale(1.5);
}

  img.frontcopyright_sel{
    width: 83% !important;
    height: 73% !important;
    z-index: -9999;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 11%;
   
  }
  .dialog_cont .com_por {
    flex-direction: column;
  }
  .dialog_cont {
    height: max-content;
  }
  .dialog_cont h5 {
    font-size: 32px;
  }
  .dialog_cont p {
    font-size: 20px;
  }
  .selected_container .head {
    padding: 10px 12px;
    flex-direction: column;
    align-items: flex-start;
  }
  .selected_container .head h5 {
    font-size: 28px;
    margin-bottom: 0;
    align-self: center;
  }

  button {
    width: 80%;
    position: fixed;
    margin-left: 5%;
    bottom: 15px;
  }

  .selected_container .head span.back {
    min-width: 65px;
    font-size: 20px;
  }
  .selected_container .head span i {
    font-size: 21px;
  }
  .favourites_container .body .images_cont .image {
    width: 50% !important;
    /*
    margin-top: 10%;*/
  }
  .favourites_container .body .images_cont .image .photo {
    /*height: 110% !important ;
    overflow: hidden;*/
  }
  .customimage_0 {

    margin-top: -3% !important;

  }

  .customimage_1 {

    margin-top: -3% !important;

  }
  /* .selected_container .body .images_cont .image .photo img {
        height: 490px !important;
        width: 380px !important;
        transform: rotate(-90deg) translate(14%, 7%);
    } */
  .selected_container .body button {
    width: 80%;
    padding: 6px 0;
  }
  .selected_container .body button i {
    font-size: 16px;
  }
  .selected_container .cropping .img {
    width: 100px;
    height: 100px;
  }
  .selected_container .cropping .img img {
    width: 100px;
    height: 100px;
  }
  .selected_container .cropping .details h6 {
    font-size: 21px;
  }
  .selected_container .cropping .details p {
    width: 100%;
    font-size: 14px;
  }
  .selected_container .cropping .details a {
    font-size: 15px;
  }
  .dialog_conta .image {
    height: 595px !important;
  }

  .selected_container .body .images_cont .image .comp_port, .dialog_conta .image .comp_port {
    width: 37px;
    height: 45px;
    background: rgb(255, 255, 255);
    border: 0px solid rgb(121, 121, 121);
    position: absolute;
    top: 10px;
    left: 10px;
    opacity: 0.5;
    cursor: pointer;
  }
}
@media (max-width: 540px) {
  /* .selected_container .body .images_cont .image .photo img {
        transform: rotate(-90deg) translate(14%, 2%);
    } */
}
@media (max-width: 500px) {
  /* .selected_container .body .images_cont .image .photo img {
        transform: rotate(-90deg) translate(14%, -6%);
    } */
}
@media (max-width: 440px) {
  /* .selected_container .body .images_cont .image .photo img {
        transform: rotate(-90deg) translate(14%, -10%);
    } */
}
