.welcome_container {
    width: 100%;
}
.welcome_container h5 {
    font-size: 40px;
    font-weight: bold;
    color: #7fdc7b;
    text-align: center;
    margin: 10px 0;
}
.welcome_container p {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
}
.welcome_container .complete_cont {
    width: fit-content;
    margin: auto;
}

.welcome_container p.complete img {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}
.welcome_container button {
    background: rgba(235, 123, 119, 1);
    width: 200px;
    display: block;
    border-radius: 5px;
    margin: auto;
    padding: 10px 0;
    color: #fff;
    font-weight: 400;
    font-size: 22px;
    border: none;
    outline: none;
}
.welcome_container button i {
    font-size: 16px;
}
@media (max-width: 375px) {

    .welcome_container p{
        font-size: 18px;
        margin-top: -10px; 
    }

    .welcome_container {
        width: 100%;
        height: 640px;
      }

      .complete_cont p{
        text-align: left !important;
        padding-left: 16px !important;
      }

}
@media (max-width: 576px) {
    .welcome_container h5 {
        font-size: 34px;
    }
    .welcome_container p {
        font-size: 18px;
        
    }
    .welcome_container p.complete {
        font-size: 20px;
    }
    .welcome_container p.complete img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
    .welcome_container button {
        /* width: 175px; */
        padding: 7px 0;
        font-size: 20px;
        bottom: 15px;
        position: fixed;
        margin: 1px 10%;
        width: 80%;
        
    }
    .welcome_container button i {
        font-size: 15px;
    }
}