.details_container {
    width: 96%;
    margin: auto;
}
.details_container .head {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.details_container .head h5 {
    font-size: 36px;
    font-weight: bold;
    color: #7fdc7b;
    text-align: center;
    margin: 10px 0;
}
.details_container .head span {
    font-size: 26px;
    font-weight: 600;
    color: #333;
    cursor: pointer;
}
.details_container .head span i {
    font-size: 30px;
    color: #111;
}
.details_container .body {
    padding: 10px 30px 0;
}
.details_container .body h6 {
    font-size: 24px;
    color: #dd817b;
    font-weight: bold;
    margin: 0;
}
.details_container .body p {
    font-size: 20px;
    color: #333;
    margin-top: 10px;
}
.details_container .body p.default {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin: 15px 0;
}
.details_container .body form label {
    font-size: 18px;
    font-weight: normal;
    display: flex;
    align-items: center;
    color: #555;
    position: relative;
}
.details_container .body form label input {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    opacity: 0;
}
.details_container .body form label span {
    width: 20px;
    height: 20px;
    position: absolute;
    border: 2px solid #999;
    border-radius: 50%;
    left: 0;
}
.details_container .body form label span::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    background: #999;
    top: 3px;
    left: 3px;
    border-radius: 50%;
    display: none;
}
.details_container .body form label input:checked + span::after {
    display: block;
}
.details_container .body form button {
    background: rgba(235, 123, 119, 1);
    width: 250px;
    display: block;
    border-radius: 5px;
    padding: 10px 0;
    color: #fff;
    font-weight: 400;
    font-size: 22px;
    border: none;
    outline: none;
}
.details_container .body form button:disabled {
    background: rgb(170, 170, 170);
}
.details_container .body form button i {
    font-size: 16px;
}
.select_manually {
    width: 100%;
    padding: 15px 0 0 25px;
}
.select_manually h6 {
    font-size: 19px !important;
    font-weight: 500 !important;
    color: #333 !important;
}
.select_manually :is(select, input) {
    width: 300px;
    border-radius: 10px;
    border: none;
    outline: none;
    background: #fff;
    margin-top: 10px;
    font-size: 17px;
    font-weight: normal;
}
.select_manually input {
    height: 40px;
    padding: 0 10px;
}
.select_manually select option {
    padding: 10px 0;
    font-size: 18px;
}
@media (max-width: 576px) {
    .details_container .head {
        padding: 10px 12px;
        flex-direction: column;
        align-items: flex-start;
    }
    .details_container .head h5 {
        font-size: 28px;
        margin-bottom: 0;
        align-self: center;
    }
    .details_container .head span {
        font-size: 20px;
    }
    .details_container .head span i {
        font-size: 21px;
    }
    .details_container .body {
        padding: 10px 25px 0;
    }
    .details_container .body h6 {
        font-size: 22px;
    }
    .details_container .body p {
        font-size: 19px;
    }
    .details_container .body p.default {
        font-size: 19px;
    }
    .details_container .body form label {
        font-size: 17px;
    }
    .details_container .body form label input {
        width: 18px;
        height: 18px;
    }
    .details_container .body form label span {
        width: 18px;
        height: 18px;
    }
    .details_container .body form label span::after {
        width: 12px;
        height: 12px;
    }
    .details_container .body form button {
        /* width: 220px; */
        padding: 7px 0;
        bottom: 15px;
        position: fixed;
        width: 80%;
    }
    .select_manually select option {
        padding: 0;
        font-size: 14px;
    }
}